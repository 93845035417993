<template>
    <div class="grow-custom-1 flex-box flexcol ai-center">
        <div class="relative-pos w-100-ps grow-custom-1 flex-box flexrow padding-h-60-px-min wrap-content-yes overflow-hidden-reponsive">
            <div class="w-100-ps relative-pos flex-box flexrow jc-center">
                <div class="w-100-ps relative-pos padding-custom-1 flex-box flexcol">
                    <div class="w-100-ps flex-box flexrow padding-bottom-05 margin-bottom-1 border-bottom border-dark-700">
                        <span class="text-dark font-bold font1050rem">Upload</span>
                    </div>
                    <div class="w-100-ps flex-box flexcol margin-bottom-1">
                        <div class="w-100 flex-box flexcol padding-bottom-05 margin-bottom-1">
                            <div class="grow-custom-1 flex-box flexcol">
                                <input @change="selectFile" type="file" ref="txtfile" name="txtfile" class="w-100-ps padding-custom-05 outline-off rounded-0 border-top-0 border-start-0 border-end-0 border-bottom border-dark-700 bg-light">
                            </div>
                            <div v-if="checkFile === false" class="flex-box flexrow">
                                <span class="margin-left-auto text-danger font-bold font08rem">File tidak boleh kosong</span>
                            </div>
                        </div>
                        <div v-if="checkFile != false" class="w-100 flex-box flexcol padding-bottom-05 margin-bottom-1">
                            <span class="font-bold font10rem padding-bottom-05">Report Number</span>
                            <div class="grow-custom-1 flex-box flexcol">
                                <input type="text" v-model="txtdocno" name="txtdocno" class="w-100-ps padding-custom-05 outline-off rounded-0 border-top-0 border-start-0 border-end-0 border-bottom border-dark-700 bg-light">
                            </div>
                        </div>

                        <div v-if="checkFile != false" class="w-100 flex-box flexcol padding-bottom-05 margin-bottom-1">
                            <span class="font-bold font10rem padding-bottom-05">Owner</span>
                            <div class="grow-custom-1 flex-box flexcol">
                                <select @change="selectOwner" v-model="txtowner" class="w-100-ps padding-custom-05 outline-off rounded-0 border-top-0 border-start-0 border-end-0 border-bottom border-dark-700 bg-light">
                                    <option disabled value="">Please select one</option>
                                    <option v-for="ownerlist in ownerlists" :key="ownerlist.id" :value="ownerlist.id">{{ ownerlist.ownername }}</option>
                                </select>
                            </div>
                        </div>
                        <div v-if="checkFile != false" class="w-100 flex-box flexcol padding-bottom-05 margin-bottom-1">
                            <span class="font-bold font10rem padding-bottom-05">Customer</span>
                            <div class="grow-custom-1 flex-box flexcol">
                                <textarea v-model="txtdocnote" class="w-100-ps padding-custom-05 outline-off rounded-0 border-top-0 border-start-0 border-end-0 border-bottom border-dark-700 bg-light"></textarea>
                            </div>
                        </div>
                        <div v-if="checkFile != false" class="w-100 flex-box flexcol padding-bottom-05 margin-bottom-1">
                            <span class="font-bold font10rem padding-bottom-05">Keyword</span>
                            <div class="grow-custom-1 flex-box flexcol">
                                <textarea v-model="txtkeyword" class="w-100-ps padding-custom-05 outline-off rounded-0 border-top-0 border-start-0 border-end-0 border-bottom border-dark-700 bg-light"></textarea>
                                <input type="hidden" v-model="txtqrcode" name="txtqrcode" class="w-100-ps padding-custom-05 outline-off rounded-0 border-top-0 border-start-0 border-end-0 border-bottom border-dark-700 bg-light">
                            </div>
                        </div>
                    </div>
                    <div class="w-100-ps flex-box flexrow margin-bottom-1">
                        <button v-if="checkFile != false" @click="uploadFile" type="button" class="btn btn-sm btn-primary grow-custom-1 padding-custom-1 rounded-0 shadow">
                            Upload
                        </button>
                        <button @click="resetInputan" type="button" class="btn btn-sm btn-secondary grow-custom-1 padding-custom-1 rounded-0 shadow">
                            Reset
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <Teleport to="body">
            <!--msg-->
            <div class="index-z-9999 absolute-pos top-0 w-100-ps h-100-vh-min h-100-ps-min padding-custom-2 flex-box flexcol ai-center jc-center ocy-dark-7" v-if="isAlertProcessing">
                <div class="grow-custom-1 flex-box flexcol ai-center jc-center">
                    <div class="grow-custom-1 w-100-ps flex-box flexcol ai-center jc-center">
                        <div class="grow-custom-1 w-100-ps flex-box flexrow ai-center jc-center">
                            <span class="margin-right-1 text-light">{{ msgtxt }}</span>
                            <div class="spinner-border text-light" role="status">
                                <span class="visually-hidden">Loading...</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Teleport>

    </div>
</template>

<script>
    import { ref } from 'vue';

    export default {
        name: 'UploadComponent',
        components: {
        },
        data() {
            return {
                baseurl : window.origin,
                vpath : window.location.pathname,
                isAlertProcessing : ref(null),
                loaderSetTimeout : null,
                msgtxt : '',
                txtfile : '',
                txtextfile : '',
                txtowner : '',
                txtdocnote : '',
                txtkeyword : '',
                txtqrcode : '',
                txtdocno : '',
                ownerlists:{},
                upfilesize : '',
                maxfilesize : 83886080,
            }
        },
        mounted() {
            this.getOwner();
        },
        computed: {
            checkFile() {
                return this.txtfile.length > 0 ? true : false
            },

        },        
        methods : {
            loadedOnce(){
                localStorage.removeItem('reloaded');
                if (localStorage.getItem('reloaded')) {
                    localStorage.removeItem('reloaded');
                } else {
                    localStorage.setItem('reloaded', '1');
                    location.reload();
                }
            },
            getOwner(){
                this.$axios.get(this.$docs+'/getowner')
                .then(response => (
                    this.ownerlists = response.data.listresult 
                ));
            },
            resetInputan(){
                const input = this.$refs.txtfile
                input.type = 'text'
                input.type = 'file'
                this.txtfile = ''
                this.txtdocno = ''
                this.txtowner = ''
                this.txtdocnote = ''
                this.txtkeyword = ''
                this.txtqrcode = ''
                this.txtextfile = ''
            },
            uploadFile(){               
                this.isAlertProcessing = ref(true)
                let filecheck = this.txtfile.length
                //let ownercheck = this.txtowner.length
                let docnotecheck = this.txtdocnote.length
                let keywordcheck = this.txtkeyword.length
                //let qrcheck = this.txtqrcode.length
                let docnocheck = this.txtdocno.length

                let formData = new FormData()

                if (filecheck > 0)
                {
                    formData.append('txtfile', this.txtfile)
                }
                else
                {
                    formData.append('txtfile', '')
                }
                /*
                if (ownercheck > 0)
                {
                    formData.append('txtowner', this.txtowner)
                }
                else
                {
                    formData.append('txtowner', '')
                }
                */
                
                if (docnotecheck > 0)
                {
                    formData.append('txtdocnote', this.txtdocnote)
                }
                else
                {
                    formData.append('txtdocnote', '')
                }
                
                if (keywordcheck > 0)
                {
                    formData.append('txtkeyword', this.txtkeyword)
                }
                else
                {
                    formData.append('txtkeyword', '')
                }

                /*
                if (qrcheck > 0)
                {
                    formData.append('txtqrcode', this.txtqrcode)
                }
                else
                {
                    formData.append('txtqrcode', '')
                }
                */

                if (docnocheck > 0)
                {
                    formData.append('txtdocno', this.txtdocno)
                    this.txtqrcode = this.getxCode(this.txtdocno)
                    formData.append('txtqrcode', this.txtqrcode)
                }
                else
                {
                    formData.append('txtdocno', '')
                    formData.append('txtqrcode', '')
                }

                formData.append('txtowner', this.txtowner)
                formData.append('txtextfile', this.txtextfile)

                this.$axios
                .post(this.$docs+'/file_upload', formData, {headers: {'Content-Type': 'multipart/form-data'}})
                .then(response => {
                    this.resetInputan()
                    this.alertProcessing(response.data.message, 2000)
                    this.loadedOnce()
                })
                .catch(error => {
                    let errkode = error.response.status
                    this.resetInputan()
                    this.alertProcessing(errkode, 2000)
                })
            },
            getxCode(xkey){
                let xno = xkey.toString().split("/")
                let key01 = xno[0]
                let key02 = xno[1]
                let key03 = xno[2]
                let key04 = xno[3]
                let key05 = xno[4]
                let xcodex = this.txtextfile
                let xnumber = key01 + '_' + key02+ '_' + key03+ '_' + key04+ '_' + key05 + '.' + xcodex
                let encodekey = btoa(xnumber)
                let qrxcode = this.$urlapp + '/' + encodekey + '1'
                return qrxcode
            },
            selectFile(event){
                let files = event.target.files[0]
                this.upfilesize = files.size

                if (this.upfilesize > this.maxfilesize)
                {
                    this.alertProcessing('Failed. Max file size 80MB', 2000)
                    this.resetInputan()
                }
                else
                {
                    let name = files.name
                    let lastDot = name.lastIndexOf('.')
                    let ext = name.substring(lastDot + 1)
                    this.txtfile = files
                    this.txtextfile = ext
                    this.txtfile.length = name.length
                }
            },

            //--msg-alert--//
            alertProcessing(txt, xout) {
                this.isAlertProcessing = ref(true)
                this.msgAlert(txt)
                this.loaderSetTimeout = setTimeout(() => {
                    this.isAlertProcessing = ref(false)
                }, xout)
            },
            msgAlert(txt) {
                this.msgtxt = txt
            },

        }
    }
</script>