import { createRouter, createWebHistory } from 'vue-router';

//--error--//
import ErrorPage from '@/components/errors/ErrorPage.vue';

//--home--//
import KontenBlank from '@/components/home/BlankComponent.vue';
import RedirectPage from '@/components/home/RedirectComponent.vue';

//--docs--//
import DocsPage from '@/components/docs/DocsComponent.vue';
import SvrPage from '@/components/admin/SvrComponent.vue';
import PmrPage from '@/components/admin/PmrComponent.vue';
import DhlPage from '@/components/admin/DhlComponent.vue';
import UploadPage from '@/components/admin/UploadComponent.vue';

//--routes--//
const routes = [
    {
        path: '/',
        component: KontenBlank,
    },
    {
        path: '/:id',
        component: RedirectPage,
    },
    {
        path: '/view/:id',
        component: DocsPage,
    },
    {
        path: '/report/service/list',
        component: SvrPage,
    },
    {
        path: '/report/preventive-maintenance/list',
        component: PmrPage,
    },
    {
        path: '/report/dhl/list',
        component: DhlPage,
    },
    {
        path: '/report/upload',
        component: UploadPage,
    },
    {
        path: '/report/modify',
    },
    {
        path: '/report/modify/dual-view',
    },
    {
        path: '/report/modify/smith',
    },
    {
        path: '/report/modify/astrophysics',
    },
    {
        path: '/error',
        component: ErrorPage
    },  
    {
        path: '/:pathMatch(.*)*', 
        redirect: '/error'
    },    

]

const router = createRouter({
    history: createWebHistory(),
    routes
})

export default router