<template>
    <div class="grow-custom-1 flex-box flexcol padding-custom-1">
        <!--Hint-->
        <div class="alert alert-warning alert-dismissible border-danger border-5 border-start border-top-0 border-bottom-0 border-end-0 rounded-0 fade show" role="alert">
            <div class="font10rem">Hint :</div> 
            <hr class="my-1">
            <div class="font10rem flex-box flexrow">
                <i class="bi bi-check font10rem text-secondary"></i>
                <span class="margin-left-05">
                    Menu report upload digunakan untuk meng-upload file report customer.
                    Dokumen ini terkoneksi dengan barcode yang terdapat pada masing-masing dokumen service report.
                </span>
            </div>
            <div class="font10rem flex-box flexrow">
                <i class="bi bi-check font10rem text-secondary"></i>
                <span class="margin-left-05">
                    Menu modify report digunakan sebagai shortcut untuk membuka report portal.
                </span>
            </div>
            <div class="font10rem noneflex-box flexrow">
                <i class="bi bi-check font10rem text-secondary"></i>
                <span class="margin-left-05">
                    Klik icon <i class="bi bi-text-right font10rem font-bold text-dark"></i> dikanan atas untuk menampilkan list menu aplikasi.
                </span>
            </div>

            <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
        </div>
        <!--endHint-->
    </div>
</template>

<script>
    export default {
        name: 'BlankComponent',
        components: {
        },        
        data() {
            return {
            }
        },
        mounted() {
            //this.goHome()
        },
        methods : {
            goHome(){
                window.open('https://fsm.co.id', '_self');
            },
        }
    }
</script>