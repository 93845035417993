import router from '@/router/index.js';
import MyConfig from '@/config/index.js';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import 'bootstrap-icons/font/bootstrap-icons.min.css';
//
import { createApp } from 'vue'
import App from './App.vue'
//
const app = createApp(App);
app.use(router);
app.use(MyConfig);
app.mount('#app');