<template>
    <div class="relative-pos grow-custom-1 flex-box flexcol">
        <div class="relative-pos index-z-999 w-100-ps-min">
            <div class="flex-box flexcol w-100 padding-custom-05">
                <div class="flex-box flexrow ai-center gap-05">
                    <div class="flex-box flexrow ai-center jc-start">
                        <img :src="myLogo" class="ocy-img-08-no-hover h-50-px" alt="logo">
                    </div>
                    <div class="grow-custom-1">&nbsp;
                    </div>
                    <div v-if="loggedIn != 'false'" class="handCursor margin-left-auto noneflex-box flexrow ai-center" data-bs-toggle="offcanvas" data-bs-target="#offcanvasLeftMenu">
                        <i class="bi bi-text-right font1075rem font-bold text-dark"></i>
                    </div>
                </div>
            </div>
        </div>

        <div class="offcanvas offcanvas-start" data-bs-scroll="true" tabindex="-1" id="offcanvasLeftMenu">
            <div class="offcanvas-header">
                <div class="w-100-ps flex-box flexrow ai-center">
                    <div class="flex-box flexrow ai-center jc-start">
                        <img :src="myLogo" class="ocy-img-08-no-hover h-50-px" alt="logo">
                    </div>
                    <button type="button" class="btn-close margin-left-auto" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                </div>
            </div>
            <div class="offcanvas-body">
                <LeftMenuComponentVue />
            </div>
        </div>

    </div>
</template>

<script>
    import LeftMenuComponentVue from '@/components/menu/LeftMenuComponent.vue';

    export default {
        name: 'HeaderComponent',
        components: {
            LeftMenuComponentVue,
        },        
        data() {
            return {
                myLogo : window.origin+'/img/FSM Delivering Right.png',
                loggedIn : localStorage.getItem('yloggedIn'),
            }
        },
        mounted() {
        },
        methods : {
        }
    }
</script>