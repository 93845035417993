<template>
    <div class="flex-box flexcol padding-top-05 padding-bottom-05">
        <div class="flex-box flexcol padding-custom-5 shadow">
            <div class="flex-box flexrow ai-center padding-left-05 padding-right-05 padding-top-1 padding-bottom-1 clr-dark-200">
                <span class="font10rem font-bold text-light letterspace15">{{ xtitle }}</span>
            </div>
            <div class="flex-box flexcol padding-custom-05 bg-light">
                <template v-if="xsts === '1'">
                    <div class="font10rem flex-box flexcol padding-custom-05 border-bottom border-secondary">
                        <div class="font-bold padding-bottom-05">
                            Customer
                        </div>
                        <div class="padding-bottom-05">
                            {{ xnote }}
                        </div>
                    </div>
                    <div class="font10rem flex-box flexcol padding-custom-05">
                        <div class="font-bold padding-bottom-05">
                            Report Number
                        </div>
                        <div class="padding-bottom-05">
                            {{ xdocno }}
                        </div>
                    </div>
                    <div class="flex-box flexcol padding-custom-05">
                        <div class="alert alert-warning alert-dismissible border-danger border-5 border-start border-top-0 border-bottom-0 border-end-0 rounded-0 fade show" role="alert">
                            <div class="font08rem">Hint :</div> 
                            <hr class="my-1">
                            <div class="font08rem flex-box flexrow">
                                <i class="bi bi-check font08rem text-secondary"></i>
                                <span class="margin-left-05">{{ xhint }}
                                </span>
                            </div>
                            <button type="button" class="btn-close font08rem" data-bs-dismiss="alert" aria-label="Close"></button>
                        </div>
                        <div class="font10rem padding-bottom-05">
                            <button @click="downloadFile(xlink)" type="button" class="btn btn-success padding-custom-1 rounded shadow">
                                <span class="margin-right-05"><i class="bi bi-download"></i></span>Download
                            </button>
                        </div>
                    </div>
                </template>
                <template v-else>
                    <span class="font10rem font-bold text-dark">{{ xmsg }}</span>
                </template>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'DescComponent',
        components: {
        },        
        data() {
            return {
                vpath : window.location.pathname,
                xdocno : '',
                xext : '',
                xnote : '',
                xlink : '',
                xmsg : '',
                xsts : '',
                xtitle : 'Downloading the FSM Service Report',
                xhint : 'Use the links below to download the FSM Service Report from our download servers.'
            }
        },
        mounted() {
            this.getPath(this.vpath)
        },
        methods : {
            getPath(xpath){                
                let xpos = xpath.toString().split("/")
                this.decodeNumber(xpos[2])
            },
            decodeNumber(xid){
                this.$axios.get(this.$docs+'/decryptkey/'+xid)
				.then(response => {
                    this.getDocument(response.data.key)
                })
            },
            getDocument(xid){
                this.searchData(xid)
            },
            searchData(key01){
                let decodekey = decodeURIComponent(key01) 
                this.$axios.get(this.$docs+'/search', { params : {key01:decodekey}})
                .then(response => {
                    if (response.data.message === '1')
                    {
                        this.xnote = response.data.xnote
                        this.xdocno = response.data.xdocno
                        this.xext = response.data.xext
                        this.xlink = response.data.xfullname
                    }
                    else
                    {
                        this.xmsg = 'File Not Found.'
                    }
                    this.xsts = response.data.message
                })
                .catch(error => {
                    let errkode = error.response.status
                    console.log(errkode)
                })
            },   
            downloadFile(xname) {
                const a = document.createElement("a");
                a.style.display = "none";
                document.body.appendChild(a);
                a.href = this.$docs+'/files/'+xname;
                a.setAttribute("download", xname);
                a.click();
                window.URL.revokeObjectURL(a.href);
                document.body.removeChild(a);
            },
        }
    }
</script>