<template>
    <div class="grow-custom-1 padding-top-1 padding-bottom-2 padding-left-05 padding-right-05 text-dark rounded-custom-0">
        <!--begin-->
        <div class="margin-bottom-05 padding-left-05 flex-box flexcol">
            <div @click="goHome" class="margin-bottom-05 flex-box flexrow ai-center handCursor">
                <span class="margin-right-03 padding-top-020 w-15-px w-15-px-min flex-box flexrow jc-start">
                    <i class="bi bi-house-door font10rem font-bold text-secondary"></i>
                </span>
                <div class="grow-custom-1 padding-left-05 flex-box flexrow jc-start">
                    <span v-if="vpath === '/'" class="font10rem text-dark">
                        Home
                    </span>
                    <span v-else class="font10rem text-secondary">
                        Home
                    </span>
                </div>
            </div>
        </div>
        <!--end-->
        <!--begin-->
        <template v-if="txtjabatan === 3">
            <div class="margin-bottom-05 padding-left-05 flex-box flexcol">
                <div @click="showxreport" class="margin-bottom-05 flex-box flexrow handCursor">
                    <span class="margin-right-03 padding-top-020 w-15-px w-15-px-min flex-box flexrow jc-start">
                        <template v-if="valxreport === '0'">
                            <i class="bi bi-chevron-right font10rem text-secondary"></i>
                        </template>
                        <template v-else>
                            <i class="bi bi-chevron-down font10rem text-secondary"></i>
                        </template>
                    </span>
                    <span class="padding-top-020 w-25-px w-25-px-min flex-box flexrow jc-center">
                        <template v-if="valxreport === '0'">
                            <i class="bi bi-folder font10rem text-secondary"></i>
                        </template>
                        <template v-else>
                            <i class="bi bi-folder2-open font10rem text-dark"></i>
                        </template>
                    </span>
                    <div class="grow-custom-1 flex-box flexrow jc-start">
                        <span v-if="vpath === '/report'" class="font10rem text-dark">
                            Report Upload
                        </span>
                        <span v-else class="font10rem text-secondary">
                            Report Upload
                        </span>
                    </div>
                </div>
                <div>
                    <div class="margin-bottom-05 flex-box flexrow ai-center">
                        <span class="w-20-px w-20-px-min flex-box flexrow jc-start">
                            &nbsp;
                        </span>
                        <div @click="goSvr" class="grow-custom-1 padding-left-05 flex-box flexrow ai-center jc-start">
                            <span class="margin-right-03 padding-top-020 w-25-px w-25-px-min flex-box flexrow jc-center">
                                <template v-if="vpath === '/report/service/list'">
                                    <i class="bi bi-folder2-open font10rem text-dark"></i>
                                </template>
                                <template v-else>
                                    <i class="bi bi-folder font10rem text-secondary"></i>
                                </template>
                            </span>
                            <span v-if="vpath === '/report/service/list'" class="font10rem text-dark handCursor">
                                Service Report ({{ countsvr }})
                            </span>
                            <span v-else class="font10rem text-secondary handCursor">
                                Service Report ({{ countsvr }})
                            </span>
                        </div>
                    </div>
                </div>
                <div>
                    <div class="margin-bottom-05 flex-box flexrow ai-center">
                        <span class="w-20-px w-20-px-min flex-box flexrow jc-start">
                            &nbsp;
                        </span>
                        <div @click="goPmr" class="grow-custom-1 padding-left-05 flex-box flexrow ai-center jc-start">
                            <span class="margin-right-03 padding-top-020 w-25-px w-25-px-min flex-box flexrow jc-center">
                                <template v-if="vpath === '/report/preventive-maintenance/list'">
                                    <i class="bi bi-folder2-open font10rem text-dark"></i>
                                </template>
                                <template v-else>
                                    <i class="bi bi-folder font10rem text-secondary"></i>
                                </template>
                            </span>
                            <span v-if="vpath === '/report/preventive-maintenance/list'" class="font10rem text-dark handCursor">
                                Preventive Maintenance ({{ countpmr }})
                            </span>
                            <span v-else class="font10rem text-secondary handCursor">
                                Preventive Maintenance ({{ countpmr }})
                            </span>
                        </div>
                    </div>
                </div>
                <div>
                    <div class="margin-bottom-05 flex-box flexrow ai-center">
                        <span class="w-20-px w-20-px-min flex-box flexrow jc-start">
                            &nbsp;
                        </span>
                        <div @click="goDhl" class="grow-custom-1 padding-left-05 flex-box flexrow ai-center jc-start">
                            <span class="margin-right-03 padding-top-020 w-25-px w-25-px-min flex-box flexrow jc-center">
                                <template v-if="vpath === '/report/dhl/list'">
                                    <i class="bi bi-folder2-open font10rem text-dark"></i>
                                </template>
                                <template v-else>
                                    <i class="bi bi-folder font10rem text-secondary"></i>
                                </template>
                            </span>
                            <span v-if="vpath === '/report/dhl/list'" class="font10rem text-dark handCursor">
                                DHL ({{ countdhl }})
                            </span>
                            <span v-else class="font10rem text-secondary handCursor">
                                DHL ({{ countdhl }})
                            </span>
                        </div>
                    </div>
                </div>
                <!--
                <div>
                    <div class="margin-bottom-05 flex-box flexrow ai-center">
                        <span class="w-20-px w-20-px-min flex-box flexrow jc-start">
                            &nbsp;
                        </span>
                        <div @click="goUpload" class="grow-custom-1 padding-left-1 flex-box flexrow ai-center jc-start">
                            <span class="margin-right-05 padding-top-020 w-15-px w-15-px-min flex-box flexrow jc-start">
                                <i class="bi bi-upload font10rem font-bold text-secondary"></i>
                            </span>
                            <span v-if="vpath === '/report/upload'" class="font10rem text-dark handCursor">
                                Upload
                            </span>
                            <span v-else class="font10rem text-secondary handCursor">
                                Upload
                            </span>
                        </div>
                    </div>
                </div>
                -->
            </div>
            <!--end-->   
            <!--begin-->
            <!--
            <div class="margin-bottom-05 padding-left-05 flex-box flexcol">
                <div class="margin-bottom-05 flex-box flexrow">
                    <span class="margin-right-03 padding-top-020 w-15-px w-15-px-min flex-box flexrow jc-start">
                        <i class="bi bi-chevron-right font10rem text-secondary"></i>
                    </span>
                    <div class="grow-custom-1 padding-left-05 flex-box flexrow">
                        <span v-if="vpath === '/report/modify'" class="font10rem text-dark">
                            Report Modify
                        </span>
                        <span v-else class="font10rem text-secondary">
                            Report Modify
                        </span>
                    </div>
                </div>
                <div>
                    <div class="margin-bottom-05 flex-box flexrow ai-center">
                        <span class="w-20-px w-20-px-min flex-box flexrow jc-start">
                            &nbsp;
                        </span>
                        <div @click="goP1Page" class="grow-custom-1 padding-left-05 flex-box flexrow ai-center jc-start">
                            <span class="margin-right-03 padding-top-020 w-25-px w-25-px-min flex-box flexrow jc-center">
                                <template v-if="vpath === '/report/modify/dual-view'">
                                    <i class="bi bi-chevron-right font10rem text-dark"></i>
                                </template>
                                <template v-else>
                                    <i class="bi bi-chevron-right font10rem text-secondary"></i>
                                </template>
                            </span>
                            <span v-if="vpath === '/report/modify/dual-view'" class="font10rem text-dark handCursor">
                                Dual View
                            </span>
                            <span v-else class="font10rem text-secondary handCursor">
                                Dual View
                            </span>
                        </div>
                    </div>
                    <div class="margin-bottom-05 flex-box flexrow ai-center">
                        <span class="w-20-px w-20-px-min flex-box flexrow jc-start">
                            &nbsp;
                        </span>
                        <div  @click="goP2Page" class="grow-custom-1 padding-left-05 flex-box flexrow ai-center jc-start">
                            <span class="margin-right-03 padding-top-020 w-25-px w-25-px-min flex-box flexrow jc-center">
                                <template v-if="vpath === '/report/modify/smith'">
                                    <i class="bi bi-chevron-right font10rem text-dark"></i>
                                </template>
                                <template v-else>
                                    <i class="bi bi-chevron-right font10rem text-secondary"></i>
                                </template>
                            </span>
                            <span v-if="vpath === '/report/modify/smith'" class="font10rem text-dark handCursor">
                                Single View Smith
                            </span>
                            <span v-else class="font10rem text-secondary handCursor">
                                Single View Smith
                            </span>
                        </div>
                    </div>
                    <div class="margin-bottom-05 flex-box flexrow ai-center">
                        <span class="w-20-px w-20-px-min flex-box flexrow jc-start">
                            &nbsp;
                        </span>
                        <div @click="goP3Page" class="grow-custom-1 padding-left-05 flex-box flexrow ai-center jc-start">
                            <span class="margin-right-03 padding-top-020 w-25-px w-25-px-min flex-box flexrow jc-center">
                                <template v-if="vpath === '/report/modifyastrophysics'">
                                    <i class="bi bi-chevron-right font10rem text-dark"></i>
                                </template>
                                <template v-else>
                                    <i class="bi bi-chevron-right font10rem text-secondary"></i>
                                </template>
                            </span>
                            <span v-if="vpath === '/report/modify/astrophysics'" class="font10rem text-dark handCursor">
                                Single View Astrophysics
                            </span>
                            <span v-else class="font10rem text-secondary handCursor">
                                Single View Astrophysics
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            -->
            <!--end-->
            <!--begin-->
            <!--
            <div class="margin-bottom-05 padding-left-05 flex-box flexcol">
                <div class="margin-bottom-05 flex-box flexrow ai-center">
                    <span class="margin-right-03 padding-top-020 w-15-px w-15-px-min flex-box flexrow jc-start">
                        <i class="bi bi-question-lg font10rem font-bold text-secondary"></i>
                    </span>
                    <div class="padding-left-05">
                        <span class="font10rem text-secondary letterspace25">FAQ</span>
                    </div>                
                </div>
            </div>
            -->
            <!--end-->
            <!--begin-->
            <div class="margin-bottom-05 padding-left-05 flex-box flexcol">
                <div @click="goUpload" class="margin-bottom-05 flex-box flexrow ai-center handCursor">
                    <span class="margin-right-03 padding-top-020 w-15-px w-15-px-min flex-box flexrow jc-start">
                        <i class="bi bi-upload font10rem font-bold text-secondary"></i>
                    </span>
                    <div class="padding-left-05">
                        <span class="font10rem text-secondary letterspace25">Upload</span>
                    </div>                
                </div>
            </div>
            <!--end-->
        </template>
        <template v-else>
            <div class="padding-left-05 flex-box flexcol">
                <div class="spinner-border text-info" role="status">
                    <span class="visually-hidden">Loading...</span>
                </div>
            </div>
        </template>
        <!--end-->
        <!--begin-->
        <div class="padding-left-05 flex-box flexcol">
            <div @click="logoutUser" class="margin-bottom-05 flex-box flexrow ai-center handCursor">
                <span class="margin-right-03 padding-top-020 w-15-px w-15-px-min flex-box flexrow jc-start">
                    <i class="bi bi-power font10rem font-bold text-secondary"></i>
                </span>
                <div class="padding-left-05">
                    <span class="font10rem text-secondary">Logout</span>
                </div>                
            </div>
        </div>
        <!--end-->

        <Teleport to="body">
            <!--msg-->
            <div class="index-z-9999 absolute-pos top-0 w-100-ps h-100-vh-min h-100-ps-min padding-custom-2 flex-box flexcol ai-center jc-center ocy-dark-7" v-if="isAlertProcessing">
                <div class="grow-custom-1 flex-box flexcol ai-center jc-center">
                    <div class="grow-custom-1 w-100-ps flex-box flexcol ai-center jc-center">
                        <div class="grow-custom-1 w-100-ps flex-box flexrow ai-center jc-center">
                            <span class="margin-right-1 text-light">{{ msgtxt }}</span>
                            <div class="spinner-border text-light" role="status">
                                <span class="visually-hidden">Loading...</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Teleport>

    </div>
</template>

<script>
    import { ref } from 'vue';

    export default {
        name: 'LeftMenuComponent',
        data() {
            return {
                img02 : window.origin+'/img/LOGO FSM - KOP.png',
                valshortcut : '0',
                vpath : window.location.pathname,
                txtTechId : '',
                txtjabatan : '',
                userid : localStorage.getItem('yloggedID'),
                loggedIn : localStorage.getItem('yloggedIn'),
                loggedAuth : localStorage.getItem('yloggedAuth'),
                loaderSetTimeout : null,
                errorcode : '',
                errortxt : '',
                valxreport : '0',
                isAlertProcessing : ref(null),
                msgtxt : '',
                countsvr : '',
                countpmr : '',
                countdhl : '',
                p1link : 'https://ui.fsm.co.id',
                p2link : 'https://pmr2front.fsm.co.id',
                p3link : 'https://pmr3front.fsm.co.id',
            }
        },
        mounted() {
            this.getUser(this.userid);
            this.getPath(this.vpath);
            this.getCountSvr();
            this.getCountPmr();
            this.getCountDhl();
        },
        computed: {
        },        
        methods : {
            loadedOnce(){
                localStorage.removeItem('reloaded');
                if (localStorage.getItem('reloaded')) {
                    localStorage.removeItem('reloaded');
                } else {
                    localStorage.setItem('reloaded', '1');
                    location.reload();
                }
            },
            goHome(){
                const path = '/'
                this.vpath = path
                if (this.$route.path !== path) this.$router.push(path)
                window.open(window.origin, '_self');
            },
            getPath(xpath)
            {
                if (xpath === '/report')
                {
                    this.valxreport = '1'
                }
                else if (xpath === '/report/service/list')
                {
                    this.valxreport = '1'
                }
                else if (xpath === '/report/preventive-maintenance/list')
                {
                    this.valxreport = '1'
                }
                else if (xpath === '/report/dhl/list')
                {
                    this.valxreport = '1'
                }
                else
                {
                    this.valxreport = '1'
                }
            },
            getUser(id){
                this.$axios.get(this.$svrapi+'/api/get_active_user/'+id)
                .then(response => (
                    this.txtTechId = response.data[0].id, 
                    this.txtjabatan = response.data[0].jabatans_id
                ));
            },

            getCountSvr(){
                this.$axios.get(this.$docs+'/getcountsvr')
                .then(response => (
                    this.countsvr = response.data.xcount 
                ));
            },
            getCountPmr(){
                this.$axios.get(this.$docs+'/getcountpmr')
                .then(response => (
                    this.countpmr = response.data.xcount 
                ));
            },
            getCountDhl(){
                this.$axios.get(this.$docs+'/getcountdhl')
                .then(response => (
                    this.countdhl = response.data.xcount 
                ));
            },

            showxreport(){
                /*
                const path = '/report'
                this.vpath = path

                let valreportnow = this.valxreport
                if (valreportnow === '1')
                {
                    this.valxreport = '0'
                }
                else
                {
                    this.valxreport = '1'
                }
                */
                //this.goHome()
                this.valxreport = '1'
            },
            showshortcut(){
                let valshortcutnow = this.valshortcut
                if (valshortcutnow === '1')
                {
                    this.valshortcut = '0'
                }
                else
                {
                    this.valshortcut = '1'
                }
            },
            goSvr(){
                const path = '/report/service/list'
                this.vpath = path
                if (this.$route.path !== path) this.$router.push(path)
                window.open(window.origin+path, '_self');
            },
            goPmr(){
                const path = '/report/preventive-maintenance/list'
                this.vpath = path
                if (this.$route.path !== path) this.$router.push(path)
                window.open(window.origin+path, '_self');
            },
            goDhl(){
                const path = '/report/dhl/list'
                this.vpath = path
                if (this.$route.path !== path) this.$router.push(path)
                window.open(window.origin+path, '_self');
            },
            goUpload(){
                const path = '/report/upload'
                this.vpath = path
                if (this.$route.path !== path) this.$router.push(path)
                window.open(window.origin+path, '_self');
            },
            logoutUser() {
                this.isAlertProcessing = ref(true)
                const id=this.userid
                this.$axios.delete(this.$svrapi+'/api/user_delete/'+id)
                    .then(response => {  
                        this.alertProcessing(response.data.message, 2000)      
                        localStorage.setItem("yloggedIn", "false")
                        localStorage.removeItem("yloggedID")
                        localStorage.removeItem("yloggedName")
                        localStorage.removeItem("ytoken")
                        localStorage.removeItem("yexptoken")
                        localStorage.removeItem("yloggedAuth")
                        this.goHome()
                });
            },
            goP1Page(){
                window.open(this.p1link, '_blank')
                this.goHome()
            },
            goP2Page(){
                window.open(this.p2link, '_blank')
                this.goHome()
            },
            goP3Page(){
                window.open(this.p3link, '_blank')
                this.goHome()
            },
            
            //--msg-alert--//
            alertProcessing(txt, xout) {
                this.isAlertProcessing = ref(true)
                this.msgAlert(txt)
                this.loaderSetTimeout = setTimeout(() => {
                    this.isAlertProcessing = ref(false)
                }, xout)
            },
            msgAlert(txt) {
                this.msgtxt = txt
            },

        }        
    }
</script>