<template>
    <div class="relative-pos grow-custom-1 flex-box flexcol ai-center">
        <div class="relative-pos w-100-ps grow-custom-1 flex-box flexrow padding-h-60-px-min wrap-content-yes overflow-hidden-reponsive">
            <div class="relative-pos w-100-ps relative-pos flex-box flexrow jc-center">
                <div class="w-100-ps relative-pos padding-custom-1 flex-box flexcol">
                    <div class="w-100-ps flex-box flexrow margin-bottom-1">
                        <span class="text-dark font-bold font1050rem">Service Report</span>
                    </div>
                    <div class="padding-bottom-05 input-group">
                        <input @keydown="searchData" type="text" class="form-control font08rem" v-model="search" name="search" placeholder="Type report number here..." autocomplete="off">
                        <button @click="searchData" class="btn btn-md bg-primary text-white" type="button">
                            <i class="bi bi-search"></i>
                        </button>
                    </div>
                    <div v-if="svrcount > 0" class="alert alert-warning alert-dismissible border-danger border-5 border-start border-top-0 border-bottom-0 border-end-0 rounded-0 fade show" role="alert">
                        <div class="font10rem">Hint :</div> 
                        <hr class="my-1">
                        <div class="font08rem flex-box flexrow">
                            <i class="bi bi-check font10rem text-secondary"></i>
                            <span class="margin-left-05">Klik report number to view detail.
                            </span>
                        </div>
                        <div class="font08rem flex-box flexrow">
                            <i class="bi bi-check font10rem text-secondary"></i>
                            <span class="margin-left-05">To upload document, klik Upload menu in the main menu.
                            </span>
                        </div>
                        <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                    </div>
                    <template v-if="svrcount > 0">
                        <div class="w-100-ps flex-box flexcol jc-start margin-top-1">
                            <span v-for="(svr, index) in svrs" :key="svr.iddoc">
                                <div @click="viewData(svr.iddoc, 
                                                        svr.owner_id,
                                                        svr.docname, 
                                                        svr.filetype, 
                                                        svr.docnote, 
                                                        svr.keyword, 
                                                        svr.qrcode,
                                                        svr.filename,
                                                        svr.published
                                                        )" 
                                        class="handCursor flex-box flexrow padding-top-05 padding-bottom-05 border-bottom font10rem">
                                    <div>
                                        {{ number+(index+1) }}&nbsp;.
                                    </div>
                                    <div class="grow-custom-1 margin-left-05 flex-box flexcol">
                                        <div class="font-bold">
                                            {{ svr.docname }}
                                        </div>
                                        <div class="text-secondary">
                                            {{ svr.docnote }}
                                        </div>
                                    </div>
                                </div>
                            </span>
                        </div>
                    </template>
                    <template v-else>
                        <span class="text-secondary font10rem">Data Not Found.</span>
                    </template>
                </div>
            </div>
        </div>
        
        <Teleport to="body">
            <!--view-->
            <div class="index-z-9999 absolute-pos top-0 w-100-ps h-100-vh-min h-100-ps-min flex-box flexcol ai-center jc-center ocy-dark-7" v-if="isViewData">
                <div @click="closeViewData" class="relative-pos grow-custom-1 w-100-ps h-90-vh flex-box flexcol ai-center">
                    &nbsp;
                </div>
                <div class="absolute-pos grow-custom-1 flex-box flexrow ai-center">
                    <div class="padding-custom-1 flex-box flexcol bg-white rounded-0 overflow-auto">
                        <div class="w-100-ps flex-box flexcol">
                            <div class="grow-custom-1 margin-bottom-05 flex-box flexrow ai-center">
                                <span class="text-dark font-bold font1025rem">Service Report</span>
                                <button @click="closeViewData" type="button" class="margin-left-auto btn-close"></button>
                            </div>
                            <div class="grow-custom-1 margin-bottom-1 flex-box flexrow">
                                <div class="w-100-ps alert alert-warning alert-dismissible border-danger border-5 border-start border-top-0 border-bottom-0 border-end-0 rounded-0 fade show" role="alert">
                                    <div class="font08rem">Hint :</div> 
                                    <hr class="my-1">
                                    <div class="font08rem flex-box flexrow">
                                        <i class="bi bi-check font10rem text-secondary"></i>
                                        <span class="margin-left-05">Scan Barcode or press Preview button to preview document.
                                        </span>
                                    </div>
                                    <div class="font08rem flex-box flexrow">
                                        <i class="bi bi-check font10rem text-secondary"></i>
                                        <span class="margin-left-05">Klik share button to share report URL.
                                        </span>
                                    </div>
                                    <div class="font08rem flex-box flexrow">
                                        <i class="bi bi-check font10rem text-secondary"></i>
                                        <span class="margin-left-05">Klik modify button to modify data.
                                        </span>
                                    </div>
                                    <div class="font08rem flex-box flexrow">
                                        <i class="bi bi-check font10rem text-secondary"></i>
                                        <span class="margin-left-05">Klik delete button to delete data.
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="grow-custom-1 flex-box flexcol">
                                <div class="flex-box flexrow jc-center">
                                    <div class="flex-box flexcol ai-center padding-right-1">
                                        <template v-if="txtqrcode.length > 0">
                                            <div class="w-100-ps flex-box flexrow ai-center padding-bottom-05">
                                                <qrcode-vue :value="txtqrcode" />
                                            </div>
                                            <!--
                                            <div class="w-100-ps flex-box flexrow ai-center padding-bottom-05">
                                                <button @click="viewReport(txtqrcode)" type="button" class="btn btn-primary grow-custom-1 rounded-0">
                                                    Preview
                                                </button>
                                            </div>
                                            <div class="w-100-ps flex-box flexrow ai-center padding-bottom-05">
                                                <button type="button" class="btn btn-success grow-custom-1 rounded-0">
                                                    Share
                                                </button>
                                            </div>
                                            -->
                                        </template>
                                        <!--
                                        <template v-else>
                                            <div class="w-100-ps flex-box flexrow ai-center padding-bottom-05">
                                                <button type="button" class="btn btn-primary grow-custom-1 rounded-0">
                                                    Upload
                                                </button>
                                            </div>
                                        </template>
                                        -->
                                    </div>
                                    <div class="grow-custom-1 flex-box flexcol ai-center">
                                        <div class="w-100 flex-box flexcol padding-bottom-05 margin-bottom-1 border-bottom border-dark-700">
                                            <span class="font-bold font10rem">Report Number</span>
                                            <div class="grow-custom-1 flex-box flexcol">{{ viewNoReport(txtdocname) }}
                                            </div>
                                        </div>
                                        <div class="w-100 flex-box flexcol padding-bottom-05 margin-bottom-1 border-bottom border-dark-700">
                                            <span class="font-bold font10rem">Customer</span>
                                            <div class="grow-custom-1 flex-box flexcol">{{ txtdocnote }}
                                            </div>
                                        </div>
                                        <div class="w-100 flex-box flexcol padding-bottom-05 margin-bottom-1 border-bottom border-dark-700">
                                            <span class="font-bold font10rem">Keyword</span>
                                            <div class="grow-custom-1 flex-box flexcol">{{ txtkeyword }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="grow-custom-1 margin-top-1 flex-box flexcol">
                                <button @click="viewReport(txtqrcode)" type="button" class="btn btn-primary grow-custom-1 margin-bottom-05 rounded-0">
                                    Preview
                                </button>
                                <button @click="openShareWindow" type="button" class="btn btn-primary grow-custom-1 margin-bottom-05 rounded-0">
                                    Share
                                </button>
                                <button @click="openModifyData" type="button" class="btn btn-primary grow-custom-1 margin-bottom-05 rounded-0">
                                    Modify
                                </button>
                                <button @click="deleteData" type="button" class="btn btn-danger grow-custom-1 margin-bottom-05 rounded-0">
                                    Delete
                                </button>
                                <button @click="closeViewData" type="button" class="btn btn-secondary grow-custom-1 margin-bottom-05 rounded-0">
                                    Close
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!--modify-->
            <div class="index-z-9999 absolute-pos top-0 w-100-ps h-100-vh-min h-100-ps-min flex-box flexcol ai-center jc-center ocy-dark-7" v-if="isModifyData">
                <div @click="closeModifyData" class="relative-pos grow-custom-1 w-100-ps h-90-vh flex-box flexcol ai-center">
                    &nbsp;
                </div>
                <div class="absolute-pos grow-custom-1 flex-box flexrow ai-center">
                    <div class="padding-custom-1 h-90-vh flex-box flexcol bg-white rounded-0 overflow-auto">
                        <div class="w-100-ps flex-box flexcol">
                            <div class="grow-custom-1 margin-bottom-05 flex-box flexrow ai-center">
                                <span class="text-dark font-bold font1025rem">Modify Data</span>
                                <button @click="closeModifyData" type="button" class="margin-left-auto btn-close"></button>
                            </div>
                            <div class="grow-custom-1 margin-bottom-1 flex-box flexrow">
                                <div class="w-100-ps alert alert-warning alert-dismissible border-danger border-5 border-start border-top-0 border-bottom-0 border-end-0 rounded-0 fade show" role="alert">
                                    <div class="font08rem">Hint :</div> 
                                    <hr class="my-1">
                                    <div class="font08rem flex-box flexrow">
                                        <i class="bi bi-check font10rem text-secondary"></i>
                                        <span class="margin-left-05">Klik update button to modify data.
                                        </span>
                                    </div>
                                    <div class="font08rem flex-box flexrow">
                                        <i class="bi bi-check font10rem text-secondary"></i>
                                        <span class="margin-left-05">Klik delete button to delete data.
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="w-100-ps flex-box flexcol margin-bottom-1">
                                <div class="w-100-ps flex-box flexcol padding-bottom-05 margin-bottom-1">
                                    <div class="grow-custom-1 flex-box flexcol">
                                        <input @change="selectFile" type="file" ref="txtfullfilename" name="txtfullfilename" class="w-100-ps padding-custom-05 outline-off rounded-0 border-top-0 border-start-0 border-end-0 border-bottom border-dark-700 bg-light">
                                        <input type="hidden" v-model="txtid" name="txtid" class="w-100-ps padding-custom-05 outline-off rounded-0 border-top-0 border-start-0 border-end-0 border-bottom border-dark-700 bg-light">
                                    </div>
                                </div>
                                <div class="w-100 flex-box flexcol padding-bottom-05 margin-bottom-1">
                                    <span class="font-bold font10rem padding-bottom-05">Report Number</span>
                                    <div class="grow-custom-1 flex-box flexcol">
                                        <input type="text" v-model="txtdocname" name="txtdocname" class="w-100-ps padding-custom-05 outline-off rounded-0 border-top-0 border-start-0 border-end-0 border-bottom border-dark-700 bg-light">
                                    </div>
                                </div>
                                <div class="w-100 flex-box flexcol padding-bottom-05 margin-bottom-1">
                                    <span class="font-bold font10rem padding-bottom-05">Owner</span>
                                    <div class="grow-custom-1 flex-box flexcol">
                                        <select @change="selectOwner" v-model="txtowner" class="w-100-ps padding-custom-05 outline-off rounded-0 border-top-0 border-start-0 border-end-0 border-bottom border-dark-700 bg-light">
                                            <option disabled value="">Please select one</option>
                                            <option v-for="ownerlist in ownerlists" :key="ownerlist.id" :value="ownerlist.id">{{ ownerlist.ownername }}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="w-100 flex-box flexcol padding-bottom-05 margin-bottom-1">
                                    <span class="font-bold font10rem padding-bottom-05">Customer</span>
                                    <div class="grow-custom-1 flex-box flexcol">
                                        <textarea v-model="txtdocnote" class="w-100-ps padding-custom-05 outline-off rounded-0 border-top-0 border-start-0 border-end-0 border-bottom border-dark-700 bg-light"></textarea>
                                    </div>
                                </div>
                                <div class="w-100 flex-box flexcol padding-bottom-05 margin-bottom-1">
                                    <span class="font-bold font10rem padding-bottom-05">Keyword</span>
                                    <div class="grow-custom-1 flex-box flexcol">
                                        <textarea v-model="txtkeyword" class="w-100-ps padding-custom-05 outline-off rounded-0 border-top-0 border-start-0 border-end-0 border-bottom border-dark-700 bg-light"></textarea>
                                        <input type="hidden" v-model="txtqrcode" name="txtqrcode" class="w-100-ps padding-custom-05 outline-off rounded-0 border-top-0 border-start-0 border-end-0 border-bottom border-dark-700 bg-light">
                                    </div>
                                </div>
                                <div class="w-100 flex-box flexcol padding-bottom-05 margin-bottom-1">
                                    <span class="font-bold font10rem padding-bottom-05">Published</span>
                                    <div class="grow-custom-1 flex-box flexcol">
                                        <select v-model="txtpublished" class="w-100-ps padding-custom-05 outline-off rounded-0 border-top-0 border-start-0 border-end-0 border-bottom border-dark-700 bg-light">
                                            <option disabled value="">Please select one</option>
                                            <option value="Y">Yes</option>
                                            <option value="N">No</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div class="grow-custom-1 margin-top-1 flex-box flexcol">
                                <button @click="saveModifyData" type="button" class="btn btn-primary grow-custom-1 margin-bottom-05 rounded-0">
                                    Save
                                </button>
                                <button @click="closeModifyData" type="button" class="btn btn-secondary grow-custom-1 margin-bottom-05 rounded-0">
                                    Close
                                </button>
                            </div>
                        </div>
                    </div>                        
                </div>
            </div>

            <!--share-->
            <div class="index-z-9999 absolute-pos top-0 w-100-ps h-100-vh-min h-100-ps-min flex-box flexcol ai-center jc-center ocy-dark-7" v-if="isShareWindow">
                <div @click="closeShareWindow" class="relative-pos grow-custom-1 w-100-ps h-90-vh flex-box flexcol ai-center">
                    &nbsp;
                </div>
                <div class="absolute-pos grow-custom-1 flex-box flexrow ai-center">
                    <div class="w-300-px-min padding-custom-1 flex-box flexcol bg-white rounded-0 overflow-auto">
                        <div class="w-100-ps flex-box flexcol">
                            <div class="grow-custom-1 margin-bottom-05 flex-box flexrow ai-center">
                                <span class="text-dark font-bold font1025rem">Report URL</span>
                                <button @click="closeShareWindow" type="button" class="margin-left-auto btn-close"></button>
                            </div>
                            <div class="w-100-ps flex-box flexcol margin-bottom-1">
                                <div class="w-100 flex-box flexcol padding-bottom-05 margin-bottom-1">
                                    <div class="grow-custom-1 flex-box flexcol">
                                        <textarea v-model="txturl" class="w-100-ps padding-custom-05 outline-off rounded-0 border-top-0 border-start-0 border-end-0 border-bottom border-dark-700 bg-light"></textarea>
                                    </div>
                                </div>
                                <div class="w-100 flex-box flexcol padding-bottom-05 margin-bottom-1">
                                    <span class="font-bold font10rem padding-bottom-05">Whatsapp Number</span>
                                    <div class="grow-custom-1 flex-box flexcol">
                                        <input type="text" v-model="txtwa" name="txtwa" class="w-100-ps padding-custom-05 outline-off rounded-0 border-top-0 border-start-0 border-end-0 border-bottom border-dark-700 bg-light">
                                    </div>
                                </div>
                            </div>
                            <div class="grow-custom-1 margin-top-1 flex-box flexcol">
                                <button @click="sendLink" type="button" class="btn btn-primary grow-custom-1 margin-bottom-05 rounded-0">
                                    Share
                                </button>
                                <button @click="closeShareWindow" type="button" class="btn btn-secondary grow-custom-1 margin-bottom-05 rounded-0">
                                    Close
                                </button>
                            </div>
                        </div>
                    </div>                        
                </div>
            </div>

            <!--msg-->
            <div class="index-z-9999 absolute-pos top-0 w-100-ps h-100-vh-min h-100-ps-min padding-custom-2 flex-box flexcol ai-center jc-center ocy-dark-7" v-if="isAlertProcessing">
                <div class="grow-custom-1 flex-box flexcol ai-center jc-center">
                    <div class="grow-custom-1 w-100-ps flex-box flexcol ai-center jc-center">
                        <div class="grow-custom-1 w-100-ps flex-box flexrow ai-center jc-center">
                            <span class="margin-right-1 text-light">{{ msgtxt }}</span>
                            <div class="spinner-border text-light" role="status">
                                <span class="visually-hidden">Loading...</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Teleport>
    </div>
</template>

<script>
    import { ref } from 'vue';
    import QrcodeVue from 'qrcode.vue';

    export default {
        name: 'SvrComponent',
        components: {
            QrcodeVue,
        },
        data() {
            return {
                baseurl : window.origin,
                token: localStorage.getItem('ytoken'),
                loggedIn: localStorage.getItem('yloggedIn'),
                loggedID: localStorage.getItem('yloggedID'),
                loggedName: localStorage.getItem('yloggedName'),
                exptoken: localStorage.getItem('yexptoken'),
                isAlertProcessing : ref(null),
                loaderSetTimeout : null,
                msgtxt : '',
                img02 : window.origin+'/img/LOGO FSM - KOP.png',
                txtTechId : '',
                userid : localStorage.getItem('yloggedID'),
                svrs:{},
                ownerlists:{},
                number : 0,
                svrcount : -1,
                search : null,
                txtid : '',
                txtowner : '', 
                txtdocname : '', 
                txtfiletype : '', 
                txtdocnote : '', 
                txtkeyword : '', 
                txtqrcode : '',
                txtfullfilename : '',
                txtpublished : '',
                isViewData : ref(null),
                isModifyData : ref(null),
                upfilesize : '',
                maxfilesize : 83886080,
                isShareWindow : ref(null),
                txturl : '',
                txtwa : '',
            }
        },
        mounted() {
            this.getSvr()
            this.getOwner()
        },
        methods : {
            loadedOnce(){
                localStorage.removeItem('reloaded');
                if (localStorage.getItem('reloaded')) {
                    localStorage.removeItem('reloaded');
                } else {
                    localStorage.setItem('reloaded', '1');
                    location.reload();
                }
            },
            getOwner(){
                this.$axios.get(this.$docs+'/getowner')
                .then(response => (
                    this.ownerlists = response.data.listresult 
                ));
            },
            getSvr(){
                this.isAlertProcessing = ref(true)
                this.alertProcessing('Please Wait', 2000)
                this.$axios.get(this.$docs+'/getsvr')
				.then(response => {
                    if (response.data.message != '0')
                    {
                        this.svrs = response.data.listresult
                        this.svrcount = 1
                        this.alertProcessing('Loading', 2000)
                    }
                    else
                    {
                        this.svrcount = 0
                        this.alertProcessing('Data Not Found', 2000)
                    }
                })
                .catch(error => {
                    let errkode = error.response.status
                    this.alertProcessing(errkode, 2000)
                })
            },
            searchData(){
                this.isAlertProcessing = ref(true)
                this.$axios.get(this.$docs+'/getsvr/', { params : {search:this.search}})
                .then(response => {
                    if (response.data.message != '0')
                    {
                        this.svrs = response.data.listresult
                        this.svrcount = 1
                        this.alertProcessing('Loading', 2000)
                    }
                    else
                    {
                        this.svrcount = 0
                        this.alertProcessing('Data Not Found', 2000)
                    }
                })
                .catch(error => {
                    let errkode = error.response.status
                    this.alertProcessing(errkode, 2000)
                })
            },            

            viewData(xid, xowner, xdocname, xfiletype, xdocnote, xkeyword, xqrcode, xfilename, xpublished) {
                this.isViewData = ref(true)
                this.txtid = xid
                this.txtowner = xowner 
                this.txtdocname = xdocname 
                this.txtfiletype = xfiletype 
                this.txtdocnote = xdocnote 
                this.txtkeyword = xkeyword 
                this.txtqrcode = xqrcode
                this.txturl = xqrcode
                this.txtfullfilename = xfilename
                this.txtpublished = xpublished
                this.viewNoReport(xdocname)
            },
            viewNoReport(xdocname){
                let xnoreport = xdocname.replaceAll("_", "/")
                return xnoreport
            },
            closeViewData() {
                this.isViewData = ref(false)
            },
            viewReport(xpath){
                window.open(xpath, '_self');
            },

            openModifyData() {
                this.isModifyData = ref(true)
                this.isViewData = ref(false)
            },
            saveModifyData() {
                this.isAlertProcessing = ref(true)
                let filecheck = this.$refs.txtfullfilename.files.length
                let docnamecheck = this.txtdocname.length
                let docnotecheck = this.txtdocnote.length
                let keywordcheck = this.txtkeyword.length

                let formData = new FormData()
                formData.append('txtowner', this.txtowner)
                formData.append('txtpublished', this.txtpublished)
                formData.append('_method', 'put')

                if (filecheck > 0)
                {
                    formData.append('txtfullfilename', this.txtfullfilename)
                    formData.append('txtextfile', this.txtfiletype)
                    //formData.append('txtqrcode', this.txtqrcode)
                }
                else
                {
                    formData.append('txtfullfilename', '')
                }

                if (docnamecheck > 0)
                {
                    formData.append('txtdocname', this.txtdocname)
                    this.txtqrcode = this.getxCode(this.txtdocname)
                    formData.append('txtqrcode', this.txtqrcode)
                }
                else
                {
                    formData.append('txtdocname', '')
                }

                if (docnotecheck > 0)
                {
                    formData.append('txtdocnote', this.txtdocnote)
                }
                else
                {
                    formData.append('txtdocnote', '')
                }

                if (keywordcheck > 0)
                {
                    formData.append('txtkeyword', this.txtkeyword)
                }
                else
                {
                    formData.append('txtkeyword', '')
                }

                this.$axios
                .post(this.$docs+'/file_update/'+this.txtid, formData, {headers: {'Content-Type': 'multipart/form-data'}})
                .then(response => {
                    this.getSvr()
                    this.resetInputan()
                    this.closeModifyData()
                    this.alertProcessing(response.data.message, 2000)
                    this.loadedOnce()
                })
                .catch(error => {
                    let errkode = error.response.status
                    this.getSvr()
                    this.resetInputan()
                    this.closeModifyData()
                    this.alertProcessing(errkode, 2000)
                })
            },
            closeModifyData() {
                this.isModifyData = ref(false)
            },
            deleteData(){
                this.isAlertProcessing = ref(true)
                let xid = this.txtid
                let xberkas = this.txtfullfilename

                this.$axios
                .delete(this.$docs+'/berkas_delete/' + xid + '/' + xberkas)
                .then(response => {
                    this.getSvr()
                    this.closeViewData()
                    this.alertProcessing(response.data.message, 2000)
                    this.loadedOnce()
                })
                .catch(error => {
                    let errkode = error.response.status
                    this.getSvr()
                    this.closeViewData()
                    this.alertProcessing(errkode, 2000)
                })
            },

            resetInputan(){
                const input = this.$refs.txtfullfilename
                input.type = 'text'
                input.type = 'file'
                this.txtid = ''
                this.txtowner = ''
                this.txtdocname = ''
                this.txtfiletype = ''
                this.txtdocnote = ''
                this.txtkeyword = ''
                this.txtqrcode = ''
                this.txtpublished = ''
            },
            selectFile(event){
                let files = event.target.files[0]
                this.upfilesize = files.size

                if (this.upfilesize > this.maxfilesize)
                {
                    this.alertProcessing('Failed. Max file size 80MB', 2000)
                    this.resetInputan()
                }
                else
                {
                    let name = files.name
                    let lastDot = name.lastIndexOf('.')
                    let ext = name.substring(lastDot + 1)
                    this.txtfullfilename = files
                    this.txtfiletype = ext
                    this.txtfullfilename.length = name.length
                    //let encodekey = btoa(name)
                    //this.txtqrcode = this.baseurl+'/' + encodekey + '1'
                }
            },
            getxCode(xkey){
                let xno = xkey.toString().split("/")
                let key01 = xno[0]
                let key02 = xno[1]
                let key03 = xno[2]
                let key04 = xno[3]
                let key05 = xno[4]
                let xcodex = this.txtfiletype
                let xnumber = key01 + '_' + key02+ '_' + key03+ '_' + key04+ '_' + key05 + '.' + xcodex
                let encodekey = btoa(xnumber)
                let qrxcode = this.$urlapp + '/' + encodekey + '1'
                return qrxcode
            },

            closeShareWindow() {
                this.isShareWindow = ref(false)
            },
            openShareWindow() {
                this.isShareWindow = ref(true)
                this.isViewData = ref(false)
            },
            sendLink(){
                let txtrequest
                let linkurl = this.txturl
                let wacust = this.txtwa
                let footerchat = this.$companyname
                
                if ((linkurl.length > 1) 
                        && (wacust.length > 1))
                {
                    txtrequest = "https://wa.me/"+wacust+"?text=" +
                                    "Dear Mr/Mrs %0a%0a" +
                                    "Bersama ini kami kirimkan Link Url " +
                                    "dokumen Service Report.%0a" + 
                                    "Link : " + linkurl + "%0a" +
                                    "Note : " + this.txtdocnote + "%0a%0a" +
                                    "Best Regards, %0a" + footerchat;

                    this.isShareWindow = ref(false)
                    window.open(txtrequest, '_blank')
                }
                else
                {
                    this.alertProcessing('FAILED. Incomplete entry.', 2000)
                }
            },

            //--msg-alert--//
            alertProcessing(txt, xout) {
                this.isAlertProcessing = ref(true)
                this.msgAlert(txt)
                this.loaderSetTimeout = setTimeout(() => {
                    this.isAlertProcessing = ref(false)
                }, xout)
            },
            msgAlert(txt) {
                this.msgtxt = txt
            },

        }
    }
</script>