<template>
    <div class="grow-custom-1 w-100-ps relative-pos flex-box flexcol ai-center jc-center">
        <div class="padding-custom-1 text-secondary font08rem">
            {{ xmsg }}
        </div>
    </div>
</template>

<script>
    export default {
        name: 'RedirectComponent',
        components: {
        },        
        data() {
            return {
                vpath : window.location.pathname,
                xmsg : 'File Not Found.',
            }
        },
        mounted() {
            this.decodeNumber(this.vpath)
        },
        methods : {
            decodeNumber(xnumber){
                let str = xnumber
                let xkey = str.substring(1, str.length - 1)
                let key64 = atob(xkey)
                this.getDocument(key64)
            },
            getDocument(xid){
                this.$axios.get(this.$docs+'/getkey/'+xid)
				.then(response => {
                    this.viewReport(response.data.key)
                })
                .catch(error => {
                    let errkode = error.response.status
                    console.log(errkode)
               })
            },
            viewReport(xcode){
                let keycode = xcode
                let routeData = this.$router.resolve({
                        path: '/view/' + keycode,
                    });
                window.open(routeData.href, '_self');
            },
        }
    }
</script>