<template>
    <div>&nbsp;</div> 
</template>

<script>
    export default {
        name: 'ErrorPage',
        data(){
            return{
            }
        },
        mounted() {
            this.goHome()
        },
        methods : {
            goHome(){
                const path = '/'
                this.vpath = path
                if (this.$route.path !== path) this.$router.push(path)
                window.open(window.origin, '_self');
            },
        }
    }
</script>