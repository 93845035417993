<template>
    <div class="grow-custom-1 w-100-ps relative-pos flex-box flexcol">
        <div class="flex-box flexcol border-bottom border-dark-700">
            <HeaderBlok />
        </div>
        <div class="grow-custom-1 flex-box flexcol">
            <div class="grow-custom-1 flex-box flexrow overflow-auto">
                <div class="w-250-px-min w-250-px x-overflow-hidden y-overflow-auto flexnone-box flexcol border-end border-dark-700">
                    <LeftMenuComponentVue />
                </div>
                <div class="relative-pos grow-custom-1 flex-box flexcol w-100-ps">
                    <PathComponentVue />
                    <router-view></router-view>
                </div>
            </div>
        </div>
        <div class="margin-top-auto flex-box flexcol border-top border-dark-700">
            <FooterBlok />
        </div>

        <!--alert-->
        <div class="index-z-9999 absolute-pos w-100-ps h-100-vh flex-box flexcol ai-center jc-center ocy-dark-7" v-if="isAlertOpen">
            <div class="grow-custom-1 w-100-ps flex-box flexcol ai-center jc-center">
                <template v-if="errorcode.length < 1">
                    <div class="grow-custom-1 w-100-ps flex-box flexrow ai-center jc-center">
                        <div class="spinner-border text-light" role="status">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                    </div>
                </template>
                <template v-else>
                    <div class="grow-custom-1 w-100-ps flex-box flexcol ai-center jc-center">
                        <div class="flex-box flexrow ai-center jc-center">
                            <span class="margin-right-1 text-light">{{ errortxt }}</span>
                            <div class="spinner-border text-light" role="status">
                                <span class="visually-hidden">Loading...</span>
                            </div>
                        </div>
                    </div>
                </template>
            </div>
        </div>
        <div class="index-z-9999 absolute-pos w-100-ps h-100-vh flex-box flexcol ai-center ocy-dark-7" v-if="isAlertProcessing">
            <div class="grow-custom-1 w-100-ps flex-box flexcol ai-center jc-center">
                <div class="grow-custom-1 w-100-ps flex-box flexrow ai-center jc-center">
                    <span class="margin-right-1 text-light">{{ msgtxt }}</span>
                    <div class="spinner-border text-light" role="status">
                        <span class="visually-hidden">Loading...</span>
                    </div>
                </div>
            </div>
        </div>
        <!--end-->

    </div>
</template>

<script setup>
    import { ref } from 'vue';
</script>

<script>
    import HeaderBlok from '@/components/home/HeaderComponent.vue';
    import FooterBlok from '@/components/home/FooterComponent.vue';
    import LeftMenuComponentVue from '@/components/menu/LeftMenuComponent.vue';
    import PathComponentVue from '@/components/home/PathComponent.vue';

    export default {
        name: 'HomeComponent',
        components: {
            HeaderBlok,
            FooterBlok,
            LeftMenuComponentVue,
            PathComponentVue
        },        
        data() {
            return {
                loggedIn: localStorage.getItem('yloggedIn'),
                vpath : window.location.pathname,
                isAlertOpen : ref(null),
                isAlertProcessing : ref(null),
                loaderSetTimeout : null,
                errorcode : '',
                errortxt : '',
            }
        },
        mounted() {
        },
        methods : {

            processingMsg(txt, xout) {
                this.isAlertOpen = ref(true)
                this.processingAlert(txt)
                this.loaderSetTimeout = setTimeout(() => {
                    this.isAlertOpen = ref(false)
                }, xout)
            },

            processingAlert(txt) {
                this.errorcode = txt
                this.errortxt = ''
            },

            getErrorMsg(errkode, xout) {
                this.isAlertOpen = ref(true)
                this.getErrorAlert(errkode)
                this.loaderSetTimeout = setTimeout(() => {
                    this.isAlertOpen = ref(false)
                }, xout)
            },

            getErrorAlert(errkode) {
                if (errkode === 404)
                {
                    this.errorcode = '404'
                    this.errortxt = this.$404msg
                }
                else if (errkode === 500)
                {
                    this.errorcode = '500'
                    this.errortxt = this.$500msg
                }
                else if (errkode === 429)
                {
                    this.errorcode = '429'
                    this.errortxt = this.$429msg
                }
                else
                {
                    this.errorcode = errkode
                    this.errortxt = this.$unknownerr
                }
            },

            alertProcessing(txt, xout) {
                this.isAlertProcessing = ref(true)
                this.msgAlert(txt)
                this.loaderSetTimeout = setTimeout(() => {
                    this.isAlertProcessing = ref(false)
                }, xout)
            },
            msgAlert(txt) {
                this.msgtxt = txt
            },
        }
    }
</script>