<template>
    <div class="grow-custom-1 flex-box flexcol ai-center jc-center">
        <div class="grow-custom-1 w-100-ps flex-box flexrow-col">
            <div class="flex-box flexcol w-250-px-min w-20-ps">
                <div class="grow-custom-1 relative-pos flex-box flexcol w-250-px-min w-20-ps">
                    <div class="grow-custom-1 padding-custom-1 flex-box flexcol">
                        <DescBlok />
                    </div>
                </div>
            </div>
            <div class="grow-custom-1 padding-custom-05 flexnone-box flexcol">
                <FileBlok />
            </div>
        </div>
    </div>
</template>

<script>
    import DescBlok from '@/components/docs/DescComponent.vue';
    import FileBlok from '@/components/docs/FileComponent.vue';

    export default {
        name: 'DocsComponent',
        components: {
            DescBlok,
            FileBlok,
        },        
        data() {
            return {
            }
        },
        mounted() {
        },
        methods : {
        }
    }
</script>