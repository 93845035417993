<template>
    <div class="grow-custom-1 flex-box flexcol ai-center">
        <template v-if="loggedIn === 'true'">
            <div class="grow-custom-1 w-100-ps flex-box flexcol ai-center jc-center">
                <HomeBlok />
            </div>
        </template>
        <template v-else>
            <template v-if="key02.length > 0">
                <div class="grow-custom-1 w-100-ps flex-box flexcol">
                    <div class="w-100-ps flex-box flexcol border-bottom border-dark-700">
                        <HeaderxBlok />
                    </div>
                    <div class="w-100-ps grow-custom-1 flex-box flexcol">
                        <router-view></router-view>
                    </div>
                    <div class="w-100-ps margin-top-auto flex-box flexcol border-top border-dark-700">
                        <FooterBlok />
                    </div>
                </div>
            </template>
            <template v-else>
                <div class="grow-custom-1 w-100-ps flex-box flexcol ai-center jc-center">
                    <LoginComponentVue />
                </div>
            </template>
        </template>
    </div>
</template>

<script>
    import HomeBlok from '@/components/home/HomeComponent.vue';
    import LoginComponentVue from '@/components/login/LoginComponent.vue';
    import HeaderxBlok from '@/components/home/HeaderxComponent.vue';
    import FooterBlok from '@/components/home/FooterComponent.vue';

    export default {
        name: 'App',
        components: {
            HomeBlok,
            LoginComponentVue,
            HeaderxBlok,
            FooterBlok,
        },        
        data() {
            return {
                loggedIn: localStorage.getItem('yloggedIn'),
                vpath : window.location.pathname,
                key02 : '',
            }
        },
        mounted() {
            this.getPath(this.vpath)
        },
        computed: {
        },        
        methods : {
            getPath(xcode){
                let xurl = xcode.toString().split("/")
                this.key02 = xurl[1]
            },
        }        
    }
</script>