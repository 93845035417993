<template>
    <div class="w-100-ps relative-pos padding-custom-1 flex-box flexcol ai-center jc-center">
        <div class="relative-pos flex-box flexcol jc-center w-50-ps bg-white rounded-custom-10 shadow">
            <div class="padding-custom-2 flex-box flexcol">
                <div class="flex-box flexrow ai-center margin-bottom-05">
                    <div class="w-25-px-min w-25-px h-25-px flex-box flexrow ai-center margin-right-05">
                        <i class="bi bi-lock-fill text-secondary font1025rem"></i>
                    </div>
                    <div class="grow-custom-1 font1050rem font-bold text-secondary letterspace15">
                        Sign In
                    </div>
                </div>
                <div class="flex-box flexrow ai-center margin-bottom-05">
                    <div class="alert alert-warning alert-dismissible fade show w-100-ps border-danger border-5 border-start border-top-0 border-bottom-0 border-end-0 rounded-0" role="alert">
                        <div class="font09rem letterspace15">Refresh Browser Before Sign In To Get APP Latest Update.</div>
                        <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                    </div>
                </div>
                <div class="flex-box flexcol margin-bottom-05">
                    <div class="flex-box flexrow ai-center">
                        <span class="font09rem">User Name</span>
                    </div>
                    <div class="flex-box flexrow ai-center">
                        <input type="text" v-model="email" name="email" class="margin-bottom-1 w-100-ps padding-custom-05 outline-off rounded-0 border-top-0 border-start-0 border-end-0 border-bottom border-dark-700 bg-light" placeholder="" autocomplete="off">
                    </div>
                </div>
                <div class="flex-box flexcol margin-bottom-05">
                    <div class="flex-box flexrow ai-center">
                        <span class="font09rem">Password</span>
                    </div>
                    <div class="flex-box flexrow ai-center">
                        <input :type="xtype" v-model="pass" name="pass" class="w-100-ps padding-custom-05 outline-off rounded-0 border-top-0 border-start-0 border-end-0 border-bottom border-dark-700 bg-light margin-right-1" placeholder="" autocomplete="off">
                        <template v-if="xtype != 'password'">
                            <i @click="showPassword" class="bi bi-eye font1025rem handCursor"></i>
                        </template>
                        <template v-else>
                            <i @click="showPassword" class="bi bi-eye-slash font1025rem handCursor"></i>
                        </template>
                    </div>
                </div>
                <div class="flex-box flexcol margin-top-2">
                    <div class="flex-box flexrow ai-center">
                        <span class="font1025rem font-bold text-secondary">{{ textkodex1 }}</span>
                    </div>
                    <div class="flex-box flexrow ai-center">
                        <input v-model="textkodex1" name="textkodex1" class="form-control" type="hidden" />
                        <input v-model="textkodex2" name="textkodex2" class="w-100-ps padding-custom-05 outline-off rounded-0 border-top-0 border-start-0 border-end-0 border-bottom border-dark-700 bg-light" type="text" placeholder="" autocomplete="off">
                    </div>
                </div>
                <div class="flex-box flexcol">
                    <button @click="loginUser" type="button" class="btn btn-primary btn-lg mt-4 mb-2 rounded w-100 letterspace15">Next</button>
                </div>
            </div>
            <div class="flex-box flexcol ai-center jc-center">
                <FooterComponentVue />
            </div>
        </div>

        <Teleport to="body">
            <!--msg-->
            <div class="index-z-9999 absolute-pos top-0 w-100-ps h-100-vh-min h-100-ps-min padding-custom-2 flex-box flexcol ai-center jc-center ocy-dark-7" v-if="isAlertProcessing">
                <div class="grow-custom-1 flex-box flexcol ai-center jc-center">
                    <div class="grow-custom-1 w-100-ps flex-box flexcol ai-center jc-center">
                        <div class="grow-custom-1 w-100-ps flex-box flexrow ai-center jc-center">
                            <span class="margin-right-1 text-light">{{ msgtxt }}</span>
                            <div class="spinner-border text-light" role="status">
                                <span class="visually-hidden">Loading...</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Teleport>
        
    </div>
</template>

<script setup>
    import { ref } from 'vue';
</script>

<script>
    import FooterComponentVue from '@/components/home/FooterComponent.vue';
    export default {
        name: 'LoginComponent',
        components: {
            FooterComponentVue,
        }, 
        data(){
            return {
                isAlertProcessing : ref(null),
                loaderSetTimeout : null,
                msgtxt : '',
                email:'',
                pass:'',
                textkodex1:'',
                textkodex2:'',
                token: localStorage.getItem('ytoken'),
                loggedIn: localStorage.getItem('yloggedIn'),
                loginFailed: null,
                fsmLogo : window.origin+'/img/imgsignin.png',
                xtype : 'password',
            };
        },
        created() {
            const rand = Math.floor(Math.random() * (99999 - 1 + 1)) + 1;
            this.textkodex1 = rand;
        },
        mounted() {
            this.currentDate();
        },
        methods : {
            currentDate() {
                let tglnow = new Date()
                let thn1 = tglnow.getFullYear()
                let bln1 = tglnow.getMonth()+1
                let tgl1 = tglnow.getDate()
                let bln2 = bln1
                let tgl2 = tgl1
                let time = tglnow.getHours() + ":" + tglnow.getMinutes() + ":" + tglnow.getSeconds()

                if (bln1 < 10)
                {
                    bln2 = '0'+bln1
                }
                else
                {
                    bln2 = bln1
                }
                
                if (tgl2 < 10)
                {
                    tgl2 = '0'+tgl1
                }
                else
                {
                    tgl2 = tgl1
                }
                
                let date = thn1+'-'+bln2+'-'+tgl2
                let nowdate = date +' '+ time
                return nowdate
            },
            showPassword(){
                let xsts = this.xtype
                if (xsts != 'password')
                {
                    this.xtype = 'password'
                }
                else
                {
                    this.xtype = 'text'
                }
            },            
            emptyLogin() {
                this.email = ''
                this.pass = ''
                this.textkodex2 = ''
            },
            loginUser() {
                this.isAlertProcessing = ref(true)

                const cmail = this.email.length;
                const cpass = this.pass.length;

                const rand1 = this.textkodex1;
                const rand2 = this.textkodex2;
                const rslt = rand1-rand2;

                if ( rslt === 0)
                {
                    if ((cmail < 2) && (cpass < 2))
                    {
                        this.alertProcessing('E-mail and Password Needed', 2000)
                        this.loginFailed = true
                        this.loggedIn = false
                    }
                    else
                    {
                        let formData = new FormData()
                        formData.append('email', this.email)
                        formData.append('pass', this.pass)

                        this.$axios
                        .post(this.$svrapi+'/api/signin', formData)
                        .then(response => {
                            this.createCookie(response.data.token, response.data.user['id'], response.data.user['name'])
                            this.alertProcessing(response.data.message, 2000)
                        })
                        .catch(error => {
                            let errkode = error.response.status
                            this.emptyLogin()
                            this.loginFailed = true
                            this.loggedIn = false
                            this.alertProcessing(errkode, 2000)
                        });
                    }
                }
                else
                {
                    this.alertProcessing('Failed', 2000);
                    this.loginFailed = true;
                    this.loggedIn = false;
                }
            },

            goHome(){
                const path = '/'
                this.vpath = path
                if (this.$route.path !== path) this.$router.push(path)
                window.open(window.origin, '_self');
            },
            createCookie(xtoken, xid, xname){
                localStorage.setItem("yloggedIn", "true")
                localStorage.setItem("yloggedID", xid)
                localStorage.setItem("yloggedName", xname)
                localStorage.setItem("ytoken", xtoken)
                localStorage.setItem("yexptoken", this.currentDate())
                this.loggedIn = true
                this.loginFailed = false
                this.goHome()
            },

            //--msg-alert--//
            alertProcessing(txt, xout) {
                this.isAlertProcessing = ref(true)
                this.msgAlert(txt)
                this.loaderSetTimeout = setTimeout(() => {
                    this.isAlertProcessing = ref(false)
                }, xout)
            },
            msgAlert(txt) {
                this.msgtxt = txt
            },
        }
    }    
</script>