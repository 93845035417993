<template>
    <div class="relative-pos grow-custom-1 flex-box flexcol">
        <div class="relative-pos index-z-999 w-100-ps-min">
            <div class="flex-box flexcol w-100 padding-custom-05">
                <div class="flex-box flexrow ai-center gap-05">
                    <div class="flex-box flexrow ai-center jc-start">
                        <img :src="myLogo" class="ocy-img-08-no-hover h-50-px" alt="logo">
                    </div>
                    <div class="grow-custom-1">&nbsp;
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'HeaderxComponent',
        components: {
        },        
        data() {
            return {
                myLogo : window.origin+'/img/FSM Delivering Right.png',
            }
        },
        mounted() {
        },
        methods : {
        }
    }
</script>