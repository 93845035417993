<template>
    <div class="w-100-ps relative-pos flex-box flexcol">
        <div class="padding-custom-1 bg-light border-bottom border-dark-700 flex-box flexrow">
            <template v-if="((key02 != 'report') && (key02 != 'view'))">
                <span @click="goPage('home')" class="handCursor">
                    <i class="bi bi-house-door font10rem font-bold text-secondary"></i>&nbsp;Home
                </span>
            </template>
            <template v-else-if="((key02 != 'report') && (key02 === 'view'))">
                <span class="font10rem text-secondary font-lowercase">
                    preview
                </span>
            </template>
            <template v-else>
                <template v-if="key03 === 'report'">
                    <span @click="goPage('home')" class="handCursor">
                        <i class="bi bi-house-door font10rem font-bold text-secondary"></i>
                    </span>
                    <span class="font10rem text-secondary font-lowercase padding-left-05 padding-right-05">-</span>
                    <span class="font10rem text-secondary font-lowercase">
                        {{ key02 }}
                    </span>
                    <span class="font10rem text-secondary font-lowercase padding-left-05 padding-right-05">-</span>
                    <span class="font10rem text-secondary font-lowercase">
                        {{ key03 }}
                    </span>
                </template>
                <template v-else-if="key03 === 'dhl'">
                    <span @click="goPage('home')" class="handCursor">
                        <i class="bi bi-house-door font10rem font-bold text-secondary"></i>
                    </span>
                    <span class="font10rem text-secondary font-lowercase padding-left-05 padding-right-05">-</span>
                    <span class="font10rem text-secondary font-lowercase">
                        {{ key02 }}
                    </span>
                    <span class="font10rem text-secondary font-lowercase padding-left-05 padding-right-05">-</span>
                    <span class="font10rem text-secondary font-lowercase">
                        {{ key03 }}
                    </span>
                </template>
                <template v-else>
                    <span @click="goPage('home')" class="handCursor">
                        <i class="bi bi-house-door font10rem font-bold text-secondary"></i>
                    </span>
                    <span class="font10rem text-secondary font-lowercase padding-left-05 padding-right-05">-</span>
                    <span class="font10rem text-secondary font-lowercase">
                        {{ key02 }}
                    </span>
                    <span class="font10rem text-secondary font-lowercase padding-left-05 padding-right-05">-</span>
                    <span class="font10rem text-secondary font-lowercase">
                        {{ key03 }}
                    </span>
                </template>
            </template>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'PathComponent',
        components: {
        },        
        data() {
            return {
                vpath : window.location.pathname,
                rpath : '',
                key01 : '',
                key02 : '',
                key03 : '',
            }
        },
        mounted() {
            this.getPath(this.vpath)
        },
        methods : {
            getPath(xcode){
                let xurl = xcode.toString().split("/")
                this.key01 = xurl[0]
                this.key02 = xurl[1]
                this.key03 = xurl[2]
            },
            goPage(xroute){
                if (xroute === 'home')
                {
                    this.goHome()
                }
                else
                {
                    this.goHome()
                }
            },
            goHome(){
                const path = '/'
                this.vpath = path
                if (this.$route.path !== path) this.$router.push(path)
                window.open(window.origin, '_self');
            },
        }
    }
</script>