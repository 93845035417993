<template>
    <div class="padding-custom-05 txt-align-center text-secondary">
        <div class="font08rem">
            ©{{ yearnow }} Fsm.Co.Id. All rights reserved.    
        </div>
    </div>
</template>

<script>
    export default {
        name: 'FooterComponent',
        data() {
            return {
                yearnow : '',
            }
        },
        mounted() {
            this.currentYear();
        },
        computed: {
        },        
        methods : {
            currentYear() {
                let tglnow = new Date()
                this.yearnow = tglnow.getFullYear()
            },            

        }        
    }
</script>